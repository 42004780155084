@mixin font-style($size:'text-body', $weight: null, $line-height: normal) {
  font-stretch: normal;
  font-family: $base-font-family;
  font-size: font-size($size);
  font-weight: font-weight($weight);
  text-rendering: geometricPrecision;
  letter-spacing: normal;
  -webkit-font-smoothing: antialiased;
  line-height: line-height($size, $line-height);
  font-style: normal;
  font-variant-ligatures: normal;
  font-variant-caps: normal;
  font-variant-numeric: normal;
  font-variant-east-asian: normal;
  word-spacing: normal;
  text-transform: none;
  text-indent: 0px;
  text-shadow: none;
  -webkit-writing-mode: horizontal-tb !important;

  @if $size == 'heading2' {
    @media only screen and (max-width: $evergreen-tablet-breakpoint) {
      font-size: font-size(heading2-tablet-mobile);
      line-height: line-height(heading2-tablet-mobile);
    }
  }

  @if $size == 'heading1' {
    @media only screen and (max-width: $evergreen-tablet-breakpoint) {
      font-size: font-size(heading1-tablet);
    }

    @media only screen and (max-width: $evergreen-mobile-breakpoint) {
      font-size: font-size(heading1-mobile);
      line-height: line-height(heading1-mobile);
    }
  }

  @if $size == 'heading1-large' {
    @media only screen and (max-width: $evergreen-tablet-breakpoint) {
      font-size: font-size(heading1-large-tablet);
      line-height: line-height(heading1-large-tablet);
    }

    @media only screen and (max-width: $evergreen-mobile-breakpoint) {
      font-size: font-size(heading1-large-mobile);
      line-height: line-height(heading1-large-mobile);
    }
  }
}
